import React, {useState, useEffect} from "react";
import { useMediaQuery } from 'react-responsive';
import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";
import { H3 } from "@leafygreen-ui/typography";
import Badge from "@leafygreen-ui/badge";
import { Link } from "react-router-dom";
import { fontFamilies } from '@leafygreen-ui/tokens';
import './mobile.css'

const cardStyle = css`
  margin: 1em;
  padding: 0px;
  max-width: 30%;
  height: auto;
`

const fontStyle = css`
font-family: ${fontFamilies.default};
`
const badgeColors = ["darkgray"];
const badgeColors2 = ["blue","red","purple"];

const getBadgeColor = tag => {
  console.log(tag)
  let tagId = tag.split("").map(char => char.charCodeAt(0)).reduce((s,a) => s + a, 0) % 6;
  return badgeColors[tagId];
}
const getBadgeColor2 = tag => {
  console.log(tag)
  let tagId = tag.split("").map(char => char.charCodeAt(0)).reduce((s,a) => s + a, 0) % 6;
  return badgeColors2[0];
}

// {(new Date(props.date)).toLocaleDateString()}
// {props.category} -- {props.type} -- {props.trialLength}<br/><br/> {props.description}<br/><br/>

export default function PostSummary(props) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 415 })
    return isDesktop ? children : null
  }
  

  useEffect(() => {
    window
    .matchMedia("(min-width: 415px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  if (true) {};
  return (
      <Card className='landing-page-style'>
        <img src={props.img} alt="" style={{'border-radius': '2px 2px 0px 0px','width': '100%','height': '200px','object-fit':'cover'}} /><br/>
        <H3 style={{'margin-left': '3%',fontFamily:'Manrope'}}>{props.Company} - ~{props.miles}</H3>
        <p style={{'opacity':'90%','text-align': 'left',fontFamily:'Manrope','padding-left':'15px','padding-right':'15px','height': 'auto','max-height': '100%','marginBottom': '1em','paddingBottom': '0px','display': 'block','flex-direction': 'column'}}>{props.Address}</p>
        <div style={{'display':'block','text-align': 'left','marginBottom': '0em',paddingLeft:'5%',fontFamily:'Manrope'}}>{props && [props.Category,props.Type] && [props.Category].map(tag => (<React.Fragment><Badge variant={getBadgeColor(tag)} style={{fontFamily:'Manrope',fontSize:'medium'}}>{tag}</Badge> </React.Fragment>))}</div>
        <div style={{'display':'block','text-align': 'left','marginBottom': '2em',paddingLeft:'5%',fontFamily:'Manrope'}}>{props && [props.Category,props.Type] && props.Type.split(",").map(tag => (<React.Fragment><Badge variant={getBadgeColor2(tag)} style={{fontFamily:'Manrope'}}>{tag}</Badge> </React.Fragment>))}</div>
        <p style={{'opacity':'90%','text-align': 'left',fontFamily:'Manrope','padding-left':'15px','padding-right':'15px','height': 'auto','max-height': '100%','marginBottom': '5em','paddingBottom': '0px','display': 'block','flex-direction': 'column'}}>{props.Description}</p>
        <div><a href={props.trialLink || props.offerLink || props.studentLink} target="_blank" rel="noreferrer" style={{'display':'block','text-align': 'center','text-decoration': 'none'}}><button style={{border:'none','display':'block',fontFamily:'Manrope','color':'white','position':'absolute','bottom':'-1px','font-weight':'bold','background-color':'black','height': '40px','cursor': 'pointer','border-radius': '0px 0px 2px 2px','width': '100%'}}>{props.Length}</button></a></div>
      </Card>
  )
}