import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button style={{borderRadius:'2px', border: '0px', backgroundColor:'white', fontFamily:'Outfit' , padding:'10px',maxHeight:'70%',alignSelf:'center',marginLeft:'auto',marginRight:'auto',color:'black'}} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button>
  );
};

export default LogoutButton;