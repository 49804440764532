import { H1 } from '@leafygreen-ui/typography';
import { MongoDBLogoMark } from '@leafygreen-ui/logo';
import './mobile.css';

export default function Header(props) {
  let wrapperStyle = {
    backgroundColor: "white",
    marginLeft: 'auto',
  }
  
  return(
    <body style={{backgroundColor:'white'}}>
      <div style={wrapperStyle}>
        <H1 class='header-style' style={{fontFamily:'Outfit'}}><a style={{textDecoration:'none',color:'black'}} href='https://tryglia.com'>(glia)</a></H1>
      </div>
    </body>
  )
}