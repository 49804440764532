import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  // appState?.returnTo || 
  //    onRedirectCallback={onRedirectCallback}
  //authorizationParams={{
  //  redirect_uri: 'http://localhost:3000',
  //}}

  // http://localhost:3000/?code=JbRbEc1mEJhmyGjbCnJWFhBNbd1E-YfyLxisLP1NHODU4&state=di5xRUgtWH5ZbnpaT2NmUlNmTVh2LWpxczY5TGw3NkRqbFJySEQxUHZ%2BXw%3D%3D

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!('dev-84wzrg7o0c5h4gc0.us.auth0.com' && 'pAdDtaEmSEXKfGOK9yJanCeke6OTD6dk' && 'http://localhost:3000')) {
    return null;
  }

  // https://www.tryglia.com
  // http://localhost:3000

  return (
    <Auth0Provider
      domain={'dev-84wzrg7o0c5h4gc0.us.auth0.com'} 
      clientId={'pAdDtaEmSEXKfGOK9yJanCeke6OTD6dk'}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      authorizationParams={{
        redirect_uri: 'https://tryglia.com/home',
      }}
      onRedirectCallback={onRedirectCallback}
      
    >
      {children}
    </Auth0Provider>
  );
};