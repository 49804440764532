import "./styles.css";
import "./fonts.css";

import LeafygreenProvider from '@leafygreen-ui/leafygreen-provider';
import Layout from "./components/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { AuthenticationGuard } from "./userAuth/AuthenticationGuard";


import Home from "./pages/Home";
import Deals from "./pages/Deals";
import Students from "./pages/Students";
import LandingPage from "./pages/LandingPage";
import Archive from "./pages/Archive";
import Create from "./pages/Create";
import Post from "./pages/Post";

/*const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

            <Route path="/students" element={<AuthenticationGuard component={Students} />} />
            <Route path="/deals" element={<AuthenticationGuard component={Deals} />} />

*/



function App() {

  const { isLoading, isAuthenticated } = useAuth0();
  
  return (
        <Routes>
        <Route path="/" element={<LandingPage/>} />
          <Route path="/home" element={<AuthenticationGuard component={Layout} />}>
            <Route path="/home" element={<AuthenticationGuard component={Home} />} />
            <Route path="/home/students" element={<AuthenticationGuard component={Students} />} />
            <Route path="/home/deals" element={<AuthenticationGuard component={Deals} />} />
          </Route>
        </Routes>
  );
}

export default App;