import React, {useState, useEffect} from "react";
import makeAnimated from 'react-select/animated'
import { H2 } from "@leafygreen-ui/typography";
import PostSummary from "../components/PostSummary";
import { baseUrl } from "../config";
import { useAuth0 } from '@auth0/auth0-react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchBar from 'material-ui-search-bar';
import './mobResp.css'
import Stack from '@mui/material/Stack';
import { useOutletContext } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const { palette } = createTheme();
const { augmentColor } = palette;

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    glia: createColor('#FBDD02')
  },
});
        


function App() {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  let [trials, setGlia] = useState([]);
  let [categ, setCategory] = useState("");
  let [typ, setType] = useState("");
  let [searchText, setSearch] = useState("");
  let [animate, setAnimated] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [zipInput, setZipInput] = useState("");
  let [num, setApiCall] = useState("");
  const [formats, setFormats] = useState("");
  const [test] = useOutletContext();
  const [isInputValid, setIsInputValid] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [helperLabel, setHelperLabel] = useState("");
  const [spinner, setSpinner] = useState(false);


  const handleFormat = (event, newFormats) => {
    console.log(newFormats)
    console.log(test)
    if (newFormats !== null) {
      setType("");
      setFormats(newFormats);
      setCategory(newFormats.charAt(0).toUpperCase() + newFormats.slice(1));
      console.log(categ);
      dynamicDropdown(newFormats)
    }
  };
  
  const aws_glia = async () => {
    console.log("WORKING")
    setSpinner(true);
    if (num == "1") {
    let URLAWS = 'https://bbi8gfnn19.execute-api.us-east-2.amazonaws.com/default/gliaPy38?';
    if (test !== '') { URLAWS = URLAWS.concat('db=',test) };
    if (categ !== '') { URLAWS = URLAWS.concat('&cat=',categ) };
    if (typ !== '') { URLAWS = URLAWS.concat('&typ=',typ) };
    if (searchInput !== '') { URLAWS = URLAWS.concat('&text=',searchInput) };
    if (zipInput !== '') { URLAWS = URLAWS.concat('&zip=',zipInput) };
    console.log(URLAWS)
    let results = await fetch(URLAWS, {
      method: 'GET',
    })
    .then(resp => resp.json());
    //console.log(results);
    //console.log(typeof results);
    setGlia(results);
    }
    
    setSpinner(false);
    setApiCall("0");
  };

  useEffect(() => {
    if (zipInput.length == 5) {
      setIsInputValid(false);
      setHelperText("");
      setHelperLabel("");
      if(num == '1') {aws_glia();}
      console.log(trials)
    } else if (categ.length > 0) {
      setIsInputValid(true);
      setHelperText("Zip Code Required!");
      setHelperLabel("Error");
    }
  }, [categ, typ, num, zipInput]);

  const handleChange = (e) => {
    console.log(e)
    e.preventDefault();
    setCategory(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    if (typ.length > 0) { setApiCall("2"); }
    else { setApiCall("1"); }
    dynamicDropdown(e.target.value.toLowerCase())
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

  const handleChangeAdvanced = (e) => {
    console.log(e.target.text);
    console.log(e.target.value);
    console.log(e);
    if (e.target.value !== 'HIIT') {
      setType(toTitleCase(e.target.value));
    } else (setType(e.target.value))
    //setApiCall("2");
    //dynamicDropdown(e.target.value.toLowerCase())
  };


  const handleChangeT = (e) => {
    e.preventDefault();
    setType(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
  };

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
  };

  function dynamicDropdown(listindex) {
    switch (listindex)
        {
        case "wellness" :
          setAnimated([
            { value: 'massage', label: 'Massage' },
            { value: 'cryotherapy', label: 'Cryotherapy' },
            { value: 'sauna', label: 'Sauna' },
            { value: 'acupuncture', label: 'Acupuncture' },
            { value: 'spa', label: 'Spa' },
            { value: 'sports recovery', label: 'Sports Recovery' },
          ])
            break;
        case "fitness" :
          setAnimated([
            { value: 'personal training', label: 'Personal Training' },
            { value: 'lifting', label: 'Lifting' },
            { value: 'gym', label: 'Gym' },
            { value: 'yoga', label: 'Yoga' },
            { value: 'barre', label: 'Barre' },
            { value: 'HIIT', label: 'HIIT' },
            { value: 'bootcamp', label: 'Bootcamp' },
            { value: 'rowing', label: 'Rowing' },
            { value: 'running', label: 'Running' },
            { value: 'cycling', label: 'Cycling' },
            { value: 'pilates', label: 'Pilates' },
            { value: 'dance', label: 'Dance' },
            { value: 'boxing', label: 'Boxing' },
            { value: 'climbing', label: 'Climbing' },
            { value: 'martial arts', label: 'Martial Arts' },
            { value: 'kickboxing', label: 'Kickboxing' },
            { value: 'outdoors', label: 'Outdoors' },
            { value: 'gym time', label: 'Gym Time' },
            { value: 'sports', label: 'Sports' },
            { value: 'crossfit', label: 'CrossFit' },
            { value: 'strength training', label: 'Strength Training' },
          ])
            break;
        case "beauty" :
          setAnimated([
            { value: 'nails', label: 'Nails' },
            { value: 'hair', label: 'Hair' },
            { value: 'lash', label: 'Lash' },
            { value: 'brows', label: 'Brows' },
            { value: 'wax', label: 'Wax' },
            { value: 'lash', label: 'Lash' },
            { value: 'botox', label: 'Botox' },
            { value: 'body sculpting', label: 'Body Sculpting' },
            { value: 'laser', label: 'Laser' },
            { value: 'microneedling', label: 'Microneedling' },
          ])
            break;
          case "martial arts" :
            setAnimated([
              { value: 'bjj', label: 'BJJ' },
              { value: 'taekwondo', label: 'Taekwondo' },
              { value: 'karate', label: 'Karate' },
              { value: 'boxing', label: 'Boxing' },
              { value: 'muay thai', label: 'Muay Thai' },
              { value: 'aikido', label: 'Aikido' }
            ])
              break;
        }
        return true;
  }
    
  const handleChangeSearchText = (e) => {
    //console.log(e);
    //setType(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
    setSearchInput(e.target.value);
    //if (categ.length > 0 ) { setApiCall("2"); }
    //else {setApiCall("3");}
    
  };
  
  const handleChangeSearchZip = (e) => {
    //console.log(e)
    //e.preventDefault();
    setZipInput(e.target.value);
    //setApiCall("4");
  };

  
/*


      const aws_glia = async () => {
        let URLAWS = 'https://bbi8gfnn19.execute-api.us-east-2.amazonaws.com/default/gliaPy38?';
        if (test !== '') { URLAWS = URLAWS.concat('db=',test) };
        if (categ !== '') { URLAWS = URLAWS.concat('&cat=',categ) };
        if (typ !== '') { URLAWS = URLAWS.concat('&typ=',typ) };
        if (searchInput !== '') { URLAWS = URLAWS.concat('&text=',searchInput) };
        if (zipInput !== '') { URLAWS = URLAWS.concat('&zip=',zipInput) };
        console.log(URLAWS)
        let results = await fetch(URLAWS, {
          method: 'GET',
        })
        .then(resp => resp.json())
        .then(console.log('DONE LOADING!'));
        //console.log(results);
        //console.log(typeof results);
        setGlia(results);
      }

<select name="selectList" id="selectList" onChange={handleChange}>
   <option selected value="Select Category"/>
   <option value="Wellness">Wellness</option>
   <option value="Fitness">Fitness</option>
   <option value="Beauty">Beauty</option>
   <option value="Martial Arts">Martial Arts</option>
</select>

<Select
              closeMenuOnSelect={false}
              key={new Date().getTime()}
              components={animatedComponents}
              defaultValue={[]}
              options={animate}
              onChange={handleChangeAdvanced}

  <input
   type="search"
   placeholder="Yoga, Cycling, Meditation..."
   onChange={handleChangeSearchText}
   value={searchInput} />
          />
          
   <input
      type="search"
      placeholder="Zip Code"
      onChange={handleChangeSearchZip}
      value={zipInput} />

      
<div style={{width:'30vw'}}><SearchBar
   placeholder="Yoga, Cycling, Sauna..."
   onChange={handleChangeSearchText}
   value={searchInput}
  />
  <SearchBar
  placeholder="Enter Your Zip Code..."
  onChange={handleChangeSearchText}
  value={searchInput}
 /></div>

<br/>

<div style={{width:'350px'}}><Select
              closeMenuOnSelect={true}
              key={new Date().getTime()}
              components={animatedComponents}
              defaultValue={[]}
              isMulti
              value={typ}
              options={animate}
              onChange={handleChangeAdvanced}
          /></div>
          
<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
            <Select
                      labelId="demo-simple-select-standard-label"
                      label='Age'
                      closeMenuOnSelect={true}
                      value={typ}
                      options={animate}
                      onChange={handleChangeAdvanced}/></FormControl>

          
          <TextField
                placeholder="Yoga, Cycling, Sauna..."
                onChange={handleChangeSearchText}
                value={searchInput}
                style={{maxWidth:'60%'}}/> 


          */

        const dropDownChange = (e) => {console.log("dropdown change")};
        const searchButton = (e) => {
          //setSpinner(true);
          setApiCall("1");
        };

        //btn.addEventListener("onClick",aws_glia);


  if (isAuthenticated) { 
  return (
    <React.Fragment>
      <div className='topSpacing' style={{width:'100%'}}></div>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <Stack direction='column' justifyContent="center" alignItems="center">      
          <TextField
                placeholder="Zip Code..."
                onChange={handleChangeSearchZip}
                value={zipInput}
                label={helperLabel}
                helperText={helperText}
                style={{maxWidth:'60%'}}
                error={isInputValid}/>
          <ToggleButtonGroup
                exclusive
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
                className="search-resp"
                style={{justifyContent:'center',margin:'1.2%',justifyItems:'center',color:"white"}}>
            <ToggleButton style={{margin:'0px'}} value="fitness" aria-label="fitness" >
              <p style={{fontFamily:'Outfit',margin:'0px'}}>Fitness</p>
            </ToggleButton>
            <ToggleButton value="wellness" aria-label="wellness">
              <p style={{fontFamily:'Outfit',margin:'0px'}}>Wellness</p>
            </ToggleButton>
            <ToggleButton value="beauty" aria-label="beauty">
              <p style={{fontFamily:'Outfit',margin:'0px'}}>Beauty</p>
            </ToggleButton>
              
          </ToggleButtonGroup>
          <Stack direction="row" spacing={2}>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Type</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          options={animate}
          label="Age"
          onChange={handleChangeAdvanced}
        >
        <option value="⬇️ Pick a category then select a type ⬇️"> -- Pick a category then select a type -- </option>
        {animate.map((classType) => <MenuItem value={classType.value}>{classType.label}</MenuItem>)}
          
        </Select>
      
    </FormControl>
        <LoadingButton color='glia' loading={spinner} loadingIndicator="Loading..." variant="contained" style={{fontFamily:'Outfit'}} onClick={searchButton}>
    Search
    </LoadingButton>
      </Stack>
          </Stack>
          </ThemeProvider>


      <div>
        <ul style={{display:'flex',flexFlow:'row wrap',flexDirection:'row',marginLeft:'0%',justifyContent:'center',padding:'0px'}}>
        {trials.map(post => {
          return(
            <PostSummary {...post} />
          )
        })}
        </ul>
      </div>
    </React.Fragment>
  )}
}

export default App;

/* 
          <div style={{display:'flex',justifyContent:'center'}}>
            <select options={animate} onChange={handleChangeAdvanced}>
                  {animate.map((classType) => <option value={classType.value}>{classType.label}</option>)}
            </select>
          </div>
          
          
    const loadGlia = async () => {
      let results = await fetch(`${baseUrl}/glia/free?cat=${categ}`).then(resp => resp.json());
      console.log(results);
      console.log(typeof results);
      setGlia(results);
    }
    const loadGliaBoth = async () => {
      let results = await fetch(`${baseUrl}/glia/freeboth?cat=${categ}&ty=${typ}`).then(resp => resp.json());
      setGlia(results);
    }
    const loadGliaType = async () => {
      let results = await fetch(`${baseUrl}/glia/freetype?ty=${typ}`).then(resp => resp.json());
      setGlia(results);
    }
    const loadZipCode = async () => {
      let results = await fetch(`${baseUrl}/glia/freezip?zip=${zipInput}`).then(resp => resp.json());
      setGlia(results);
    }
    //if(num == '1') {loadGlia();}
    //if(num == '2') {loadGliaBoth();}
    //if(num == '3') {loadGliaType();}
    //if(num == '4') {loadZipCode();}
          
          
          */