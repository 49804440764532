import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { baseUrl } from "../config";
import LandingPagePost from "../components/LandingPagePost";
import './pages.css';

export default function App() {
// frameborder="0" width="100%" height="100%" style={{"width":"100%",'height':'100%','position':'absolute','left':'0px','top':'0px','overflow':'hidden'}}
// style={{"width":"100%","height":"0px",'position':'relative','paddingBottom':'56.250%'}}
// <iframe src="https://streamable.com/e/ir6nse?autoplay=1&nocontrols=1" frameborder="0" width="100%" height="100%" allowFullScreen  allow="autoplay" style={{'width':'100%','height':'100%','position':'absolute','left':'0px','top':'30px','overflow':'hidden'}}></iframe>

/* <div>
        <ul style={{display:'flex',flexFlow:'row wrap',flexDirection:'row'}}>
        {trials.map(post => {
          return(
            <PostSummary {...post} />
          )
        })}
        </ul>
      </div>
      
      
              <h2 style={{maxWidth:'52vw',marginLeft:'25vw',marginTop:'1.25vw',textAlign:'right',fontFamily:'Outfit',fontSize:'1.5vw',display:'inline-block',justifySelf:'center'}}>Joining glia is more than just working out - it's a connection to others who share your vision for a healthier, happier life. Experience Austin's vibrant fitness community and be part of something larger than yourself.</h2>
              
          
          <div>
          <img className='responsive3' style={{marginBottom:'0'}} src="https://i.ibb.co/2y5b85y/Screenshot-2023-04-22-at-12-43-20-PM.png" alt="pexels-karolina-grabowska-4210340"></img>
          </div>

          
            <h1 class='mobHead22' style={{fontFamily:'Manrope','marginTop':'0em',backgroundColor:'white',color:'black'}}>with our <u>deals</u> search engine.</h1>
              

            

      <div style={{position:'relative',margin:'0 0 0 0'}}>
        <ul style={{left:'50%',display:'flex',flexFlow:'row wrap',flexDirection:'row',maxWidth:'100%',margin:'0 0 0 0',padding:'0 0 0 0',display:'inline'}}>
        {trials.map(post => {
          return(
            <LandingPagePost style={{margin:'0px 0px 0px 0px'}} {...post} />
          )
        })}
        </ul>
        <form class='buttonStyle' style={{width:'100%'}} action="https://tryglia.com/home">
          <input class='inputStyle' type="submit" style={{display:'block',marginTop:'45px',transform:'translate(-50%,-50%)',marginLeft:'50%',backgroundColor:'#FBDD02',fontFamily:'Outfit',color:'black'}} value="View More Offers" />
        </form>
      </div>
          
          <div style={{textAlign:'center'}}>
            <h1 class='mobHead21 subBank' style={{fontFamily:'Outfit'}}>(gl)<p style={{fontSize:'4vh',display:'inline'}}>impse of euphor</p>(ia)</h1>
            <h1 class='mobHead22' style={{fontFamily:'Outfit','marginTop':'0em',backgroundColor:'white',color:'black'}}></h1>
          </div>

              */

  let trials = [{'Address': "408 W 2nd St, Austin, TX 78701",
    'Category': "Fitness",
    'Company': "Barry's",
    'Description': "Ultimate fitness experience that combines cardio and lifting. With heart-pumping music, disco lights, and energetic trainers, you'll forget you're even working out!",
    'Length': "Free Class",
    'Type': "Strength Training, Running, HIIT, Bootcamp",
    'img': "https://newsroom.mastercard.com/news/media/jxyf52es/barrys_share.png",
    'miles': "7.5 mi",
    'trialLink': "https://www.tryglia.com/home",
    'zipCode': "78701"}]

  return (
    <body>
        <div className="page-container">
          <div className="header">
              <h2 class='headerText'>(glia) <p style={{fontSize:'2vh',display:'inline'}}>trial, no error</p></h2> 
              <div class='headerButtons'>
                <form class='buttonStyle marginR' action='https://tryglia.com/home'>
                  <input class='inputStyle' type="submit" style={{backgroundColor:'#FFF',fontFamily:'Outfit',color:'black'}} value="Login" />
                </form>
                <form class='buttonStyle' action="https://tryglia.com/home">
                  <input class='inputStyle' type="submit" style={{backgroundColor:'#FBDD02',fontFamily:'Outfit',color:'black'}} value="Free Trials" />
                </form>
              </div>
          </div>

          <div class='videoFrame'>
            <div>
              <div class='noError' style={{fontFamily:'Manrope'}}>Let us do the searching for you.</div>
              <div class='subError' style={{fontFamily:'Outfit'}}>Austin's first fitness, wellness, and beauty hub for everything free</div>
              <form class='buttonStyle' action="https://tryglia.com/home">
                <input class='inputStyle topMidBut' type="submit" style={{fontFamily:'Outfit',transform:'translate(-50%,-50%)',left:'50%',borderRadius:'2px',fontSize:'medium',color:'black',padding:'1.5vw',border:'none',backgroundColor:'#FBDD02',position:'absolute',justifyContent:'center',display:'flex'}} value="Join for Free" />
              </form>
            </div>
          </div>

          <div style={{width:'100%',padding:'0vh 0vh 4vh 0vh',marginBottom:'0vh'}}>
            <div style={{backgroundImage:'url(https://i.ibb.co/DgYs8DH/image-1.png)',width:'100%',height:'60vh',backgroundSize:'cover',backgroundPosition:'center'}}>
            </div>
          </div>
          
          <div style={{width:'100%'}}>
          <img className='responsive3' style={{marginBottom:'0'}} src="https://i.ibb.co/2y5b85y/Screenshot-2023-04-22-at-12-43-20-PM.png" alt="pexels-karolina-grabowska-4210340"></img>
          </div>
          
          <div style={{textAlign:'center'}}>
            <h1 class='mobHead21 subBank' style={{fontFamily:'Manrope'}}>discover what drives you</h1>
            <h1 class='mobHead22' style={{fontFamily:'Outfit','marginTop':'0em',backgroundColor:'white',color:'black'}}></h1>
          </div>


            
            <div>
                <div class='categoryDiv' >
                  <div class='categDesign' style={{display:'grid'}}>
                    <img className='responsive2' src="https://i.ibb.co/Y8VL7R3/austin-wilcox-HCeif-KAh-HNE-unsplash.jpg" alt="pexels-nick-wehrli-5345858"></img>
                    <h1 className='descript'>Fitness</h1>
                    <h3 className='explain'>Yoga<br/>Barre<br/>Boxing<br/>HIIT<br/>Cycling<br/>Martial Arts<br/></h3>
                    <form class='blueButton' action="https://tryglia.com/home">
                      <input class='inputStyle' type="submit" style={{fontFamily: 'Outfit',backgroundColor:'#FBDD02',color:'black'}} value="Browse More" />
                    </form>
                  </div>
                  <div class='categDesign' style={{display:'grid'}}>
                    <img className='responsive2' src="https://i.ibb.co/vP0yVMJ/ivana-cajina-dn-L6-ZIpht2s-unsplash.jpg" alt="pexels-cottonbro-studio-3997993"></img>
                    <h1 className='descript'>Beauty</h1>
                    <h3 className='explain'>Wax<br/>Laser<br/>Facial<br/>Tan<br/>Salon<br/>Nails<br/></h3>
                    <form class='blueButton' action="https://tryglia.com/home">
                      <input class='inputStyle' type="submit" style={{fontFamily: 'Outfit',backgroundColor:'#FBDD02',color:'black'}} value="View More" />
                    </form>
                  </div>
                  <div class='categDesign' style={{display:'grid'}}>
                    <img className='responsive2' src="https://i.ibb.co/jvKXg5n/image-18.png" alt="pexels-karolina-grabowska-4210340"></img>
                    <h1 className='descript'>Wellness</h1>
                    <h3 className='explain'>Massage<br/>Sauna<br/>Spa<br/>Acupuncture<br/>Cryotherapy<br/><br/></h3>
                    <form class='blueButton' action="https://tryglia.com/home">
                      <input class='inputStyle' type="submit" style={{fontFamily: 'Outfit',backgroundColor:'#FBDD02',color:'black'}} value="Search Services" />
                    </form>
                  
                  </div>
                </div>
          </div>

          
          <div style={{color:'black',marginTop:'0vh'}}>
                <h1 class='mobText3' style={{'textAlign':'center','marginBottom':'0em',fontFamily:'Outfit'}}>From free trials to special offers and student discounts,</h1>
                <h1 class='mobText3' style={{'textAlign':'center','marginTop':'0em',fontFamily:'Outfit'}}>glia has compiled <u>hundreds</u> of deals</h1>
            </div>

          
      <div style={{position:'relative',margin:'0 0 4vh 0'}}>
        <ul style={{left:'50%',display:'flex',flexFlow:'row wrap',flexDirection:'row',maxWidth:'100%',margin:'0 0 0 0',padding:'0 0 0 0',display:'inline'}}>
        {trials.map(post => {
          return(
            <LandingPagePost style={{margin:'0px 0px 0px 0px'}} {...post} />
          )
        })}
        </ul>
      </div>

          

          <div className="footerGlia">
              <h2 style={{marginLeft:'5%',paddingBottom:'0',marginBottom:'3.75vw',fontFamily:'Outfit',fontSize:'4vw',display:'inline-block'}}>(glia)</h2>
              <h2 style={{marginLeft:'40vw',paddingBottom:'0',marginBottom:'0vw',fontFamily:'Outfit',fontSize:'2vw',display:'inline-block'}}>Copyright 2023. All Rights Reserved.</h2>
          </div>
        </div>

    </body>
  )
}