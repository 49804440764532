import React, {useState, useEffect} from "react";
import { H2 } from "@leafygreen-ui/typography";
import PostSummary from "../components/PostSummary";
import { baseUrl } from "../config";

export default function App() {
  let [posts, setPosts] = useState([]);
  let [trials, setGlia] = useState([]);

  useEffect(() => {
    /*const loadPosts = async () => {
      let results = await fetch(`${baseUrl}/posts/latest`).then(resp => resp.json());
      setPosts(results);
    }*/
    const loadGlia = async () => {
      let results = await fetch(`${baseUrl}/glia/free`).then(resp => resp.json());
      setGlia(results);
    }

    loadGlia();
  }, []);

  return (
    <React.Fragment>
      <H2>Student Discounts</H2>
      <div>
        {trials.map(post => {
          return(
            <PostSummary {...post} key={post._id} />
          )
        })}
      </div>
    </React.Fragment>
  )
}