import Header from "./Header";
import Navigation from "./Navigation";
import React, {useState, useEffect} from "react";
import { css } from "@leafygreen-ui/emotion";
import { Outlet } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import makeAnimated from 'react-select/animated'
import { H2 } from "@leafygreen-ui/typography";
import { baseUrl } from "../config";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './mobile.css'
import LogoutButton from "../pages/logout";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  buttonPadding: {    
    padding: '30px',   
  },
});


const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#08B9EB',
      contrastText: '#fff',
      opacity: '100%'
    },
  },
});


const gridStyle = css`
  display: grid;
  top: 0px;
  grid-template:
    [header-start] "header header" 8vw [header-end body-start]
    "side-nav body" auto [body-end] / auto 1fr;
  max-width: 100%;
  margin: 0px;
`;

const headerStyle = css`
  grid-area: header;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: white;
  height: 70px;
`;

const sideNavStyle = css`
  grid-area: side-nav;
`;

const mainStyle = css`
  grid-area: body;
  padding: 0px;
  background-color: white;
`;

/*

            <ThemeProvider theme={darkTheme}>
              <CssBaseline/>

              </ThemeProvider>

*/

export default function Layout(props) {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  const [test, setContent] = useState("freetrials");

  const handleFormat = (event, newFormats) => {
    console.log('new');
    console.log(event);
    if (newFormats !== null) {
      setContent(event.target.value);
    }
  };

  if (isAuthenticated) {
  return(
    <div className={gridStyle}>
      <section className={headerStyle}>
        <div className="head-container-test">
        <Header/>
        </div>

        <div className="head-container-test2">
          
          <Stack direction="row">
            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-standard-label">Offer Type</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={test}
                  onChange={handleFormat}
                  label="Offers"
                >
                <MenuItem value={"freetrials"}>Free Trials</MenuItem>
                <MenuItem value={"firsTime"}>First Time Offers</MenuItem>
                <MenuItem value={"stuDiscounts"}>Student Discounts</MenuItem>
                </Select>
            </FormControl>
            </Stack>
          
        </div>
        
        <div className="head-container-test3">
        <LogoutButton/>
        </div>

      

      </section>

      <section className={mainStyle}>
        <Outlet context={[test]} />
      </section>
    </div>
  )};
}

/* <ToggleButtonGroup
                value={content}
                color="neutral"
                exclusive
                onChange={handleFormat}
                aria-label="text formatting"
                className="head-container-test"
                sx ={{width:'100%',height:'5vw',marginRight:'2vw',marginTop:'1%',opacity:'2'}}>

                  <ToggleButton value="freetrials" aria-label="freetrials">
                    <p style={{fontFamily:'Outfit',fontSize:'1.75vw',color:'white',padding:'1vw 2vw 1vw 2vw'}}>Free Trials</p>
                  </ToggleButton>
                  <ToggleButton value="firsTime" aria-label="firsTime">
                    <p style={{fontFamily:'Outfit',fontSize:'1.75vw',color:'white'}}>First Time Offers</p>
                  </ToggleButton>
                  <ToggleButton value="stuDiscounts" aria-label="stuDiscounts">
                    <p style={{fontFamily:'Outfit',fontSize:'1.75vw',color:'white'}}>Student Discounts</p>
                  </ToggleButton>
                </ToggleButtonGroup> */